<template>
  <div class="hello">
    <div class="container">
      <a :href="left.ad_link" class="primary-logo" v-if="left.ad_link">
        <img :src="left.ad_image" v-if="left.type=='image'" alt="">
        <font style="vertical-align: inherit;" v-if="left.type=='text'">
          <font style="vertical-align: inherit;">{{ left.ad_content }}</font>
        </font>
      </a>
      <nav class="primary-menu" v-if="right.ad_link">
        <img :src="right.ad_image" v-if="right.type=='image'" alt="">
        <ul v-if="right.type=='text'">
          <li>
            <a :href="right.ad_link" target="_blank">
              <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">{{ right.ad_content }}</font>
              </font>
            </a>
          </li>
        </ul>
      </nav>
    </div>

  </div>
</template>

<script>
export default {
  name: 'sit-header',
  props: {
    left: {
      type: Object,
      default: () => ({})
    },
    right: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
<style lang="less" scoped>
.hello {
  margin: 0;
  padding: 5px 0;
  box-shadow: 0 0 10px #666;
  background: #fff;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 1000;

  .container {
    max-width: 980px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .primary-logo {
      display: inline-block;

      img {
        border: 0;
        max-width: 100%;
        margin: .2rem 0 0;
      }
    }

    .primary-menu {
      margin: 0;
      padding: 0;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          display: inline-block;
          padding: 8px 0;

          a {
            display: block;
            padding: 1px 20px;
            text-decoration: none;
            color: inherit;
            font-size: .813em;
            letter-spacing: 1px;
            border: 1px solid transparent;
            border-radius: 100px;
            font-weight: 600;
            transition: all .8s ease;
            -webkit-transition: all .8s ease;
            -moz-transition: all ease .8s;
          }
        }
      }
    }
  }
}

</style>
