<template>
  <div class="scratch-card">
    <!-- 内容区域 -->
    <div class="content" ref="content">
      <slot></slot>
    </div>

    <!-- 刮刮乐涂层 -->
    <canvas
        ref="canvas"
        v-show="isMobile && enableMask"
        class="canvas-overlay"
    ></canvas>
  </div>
</template>

<script>
export default {
  name: "ScratchCard",
  props: {
    clearThreshold: {
      type: Number,
      default: 50, // 擦除面积阈值百分比
    },
    coverColor: {
      type: String,
      default: "grey", // 涂层颜色
    },
    hintText: {
      type: String,
      default: "轻划刮开当期开奖", // 提示文字内容
    },
    textColor: {
      type: String,
      default: "red", // 提示文字颜色
    },
    textFont: {
      type: String,
      default: "18px Arial", // 提示文字字体
    },
    enableMask: {
      type: Boolean,
      default: true, // 是否启用刮刮乐功能
    },
  },
  data() {
    return {
      canvasWidth: 0, // 动态宽度
      canvasHeight: 0, // 动态高度
      context: null, // canvas 2D 上下文
      isMobile: true, // 是否为移动端
    };
  },
  mounted() {
    this.detectDevice(); // 检测设备类型
    this.isMobile = true;

    if (this.isMobile && this.enableMask) {
      this.setCanvasSize();

      const canvas = this.$refs.canvas;
      this.context = canvas.getContext("2d");

      this.initCanvas();

      // 添加事件监听
      canvas.addEventListener("mousemove", this.onScratch);
      canvas.addEventListener("touchmove", this.onTouchScratch);

      // 监听窗口大小变化，更新 canvas 尺寸
      window.addEventListener("resize", this.setCanvasSize);
    }
  },
  beforeDestroy() {
    this.removeListeners();
  },
  methods: {
    // 检测设备类型
    detectDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      this.isMobile = /mobile|tablet|ip(ad|hone|od)|android/i.test(userAgent);
    },
    // 设置 canvas 尺寸为父容器大小
    setCanvasSize() {
      const container = this.$refs.canvas.parentElement;
      this.canvasWidth = container.offsetWidth;
      this.canvasHeight = container.offsetHeight;

      const canvas = this.$refs.canvas;
      canvas.width = this.canvasWidth;
      canvas.height = this.canvasHeight;

      this.initCanvas();
    },

    // 初始化涂层
    initCanvas() {
      const { context, canvasWidth, canvasHeight, coverColor, hintText, textColor, textFont } = this;

      if (context) {
        // 绘制涂层
        context.fillStyle = coverColor;
        context.fillRect(0, 0, canvasWidth, canvasHeight);

        // 绘制提示文字
        context.font = textFont;
        context.fillStyle = textColor;
        context.textAlign = "center";
        context.textBaseline = "middle";
        context.fillText(hintText, canvasWidth / 2, canvasHeight / 2);
      }
    },

    // 刮擦效果
    scratch(x, y) {
      const { context } = this;
      context.globalCompositeOperation = "destination-out"; // 橡皮擦模式
      context.beginPath();
      context.arc(x, y, 15, 0, Math.PI * 2); // 圆形刮擦
      context.fill();
    },

    // 鼠标事件
    onScratch(e) {
      if (e.buttons === 1) {
        const rect = this.$refs.canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        this.scratch(x, y);
        this.checkClearArea();
      }
    },

    // 触摸事件
    onTouchScratch(e) {
      e.preventDefault();
      const touch = e.touches[0];
      const rect = this.$refs.canvas.getBoundingClientRect();
      const x = touch.clientX - rect.left;
      const y = touch.clientY - rect.top;
      this.scratch(x, y);
      this.checkClearArea();
    },

    // 计算擦除面积百分比
    calcClearArea() {
      const { context, canvasWidth, canvasHeight } = this;
      const pixels = context.getImageData(0, 0, canvasWidth, canvasHeight).data;
      let transparentPixels = 0;

      for (let i = 0; i < pixels.length; i += 4) {
        if (pixels[i + 3] === 0) transparentPixels++;
      }

      return (transparentPixels / (pixels.length / 4)) * 100;
    },

    // 检查清除面积
    checkClearArea() {
      if (this.calcClearArea() >= this.clearThreshold) {
        this.$refs.canvas.style.display = "none"; // 隐藏涂层
        this.$emit("cleared"); // 触发清除完成事件
        this.removeListeners(); // 移除事件监听
      }
    },

    // 移除事件监听
    removeListeners() {
      const canvas = this.$refs.canvas;
      canvas.removeEventListener("mousemove", this.onScratch);
      canvas.removeEventListener("touchmove", this.onTouchScratch);
      window.removeEventListener("resize", this.setCanvasSize);
    },
  },
};
</script>

<style scoped>
.scratch-card {
  position: relative;
  width: 100%; /* 自适应父容器宽度 */
  height: 100%; /* 自适应父容器高度 */
  overflow: hidden;
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
}

.canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
</style>
