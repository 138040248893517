<template>
    <div class="container">
        <!-- <div class="lot-type lot-types" style="height: 39px;">
            <div class="div" style="width: 100%;"  @click="showCai"><a :class="{active:isShow}">港彩走势</a></div>
        </div> -->

        <div class="home-lottery" v-if="info">
            <div class="" style="border: 1px solid rgb(222, 222, 222);">
                <label>港彩开奖直播 <b>{{info.issue}}</b>期 开奖结果</label>
                <div class="code-view">
                    <div style="width: 100%; display: flex; justify-content: center; height: 40px; padding-top: 6px; border-top: 1px solid rgb(222, 222, 222);">
                        <template  v-for="(item,index) in info.value_num" :key='index'>
                            <div  class="div" v-if="index<info.value_num.length-1">
                                <b :class="`bs${quling(item)} ${color[info.value_color[index]]}`">{{item}}</b>
                            </div>
                        </template>
                        <div class="div"><div class="zmdi-plus">+</div></div>
                          <div class="div">
                            <b :class="`bs${quling(info.value_num[info.value_num.length-1])} ${color[info.value_color[info.value_num.length-1]]}`">
                                {{info.value_num[info.value_num.length-1]}}</b>
                        </div>
                        </div>
                        <div class="div_wd">
                            <template  v-for="(item,index) in info.shengxiao" :key='index'>
                                <div v-if="index<info.shengxiao.length-1">
                                    <span>{{item}}<i>/</i><b :class="`zs${quling(info.value_num[index])}`">{{info.wuxing[index]}}</b></span>
                                </div>
                            </template>

                        <div><span></span></div>
                        <div><span>{{info.shengxiao[info.shengxiao.length-1]}}<i>/</i>
                            <b :class="`zs${quling(info.value_num[info.shengxiao.length-1])}`">{{info.wuxing[info.shengxiao.length-1]}}</b>
                            </span>
                        </div>
                        </div>
                    </div>
                    <div class="djsLhc">第{{parseInt(info.issue)+1}}期开奖时间：周二、周四、周六 21:30</div>
                </div>
         </div>

         <div style="padding: 4px 0.5rem; margin-bottom: 8px;" v-if="isShow">
            <div duration="500" style="margin-bottom: 10px; border: 1px solid rgb(222, 222, 222);">
                <div  class="tab-navigator">
                    <div v-for="(item,index) in nav" :key="index" class="nav" @click="currentIndex=index" :class="{active1:index===currentIndex}">{{item}}</div>
                </div>
                <div class="custom class" v-if="currentIndex==0">
                    <ul class="listHistory">
                        <li v-for="(item,index) in list" :key="index">
                            第{{item.issue}}期 {{formatDate(item.drawTime_text)}}
                            <div class="bmrender">
                                <template v-for="(jtem,jndex) in item.value_num" :key="jtem">
                                    <div class="bmnum" :class="`bmnum${quling(jtem)}`" v-if="jndex<item.value_num.length-1" >
                                        <div class="n">{{jtem}}</div>
                                        <div class="v">{{item.shengxiao[jndex]}}</div>
                                    </div>
                                </template>
                                <div class="bmnum bmplus"></div>
                                <div class="bmnum bmnum"  :class="`bmnum${quling(item.value_num[item.value_num.length-1])}`">
                                    <div class="n">{{item.value_num[item.value_num.length-1]}}</div>
                                    <div class="v">{{item.shengxiao[item.value_num.length-1]}}</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="custom class" v-if="currentIndex==1">
                    <table class="table1">
                        <thead>
                            <tr>
                                <th style="width: 15%;">期号</th>
                                <th>0</th> <th>1</th> <th>2</th>
                                <th>3</th> <th>4</th> <th>5</th>
                                <th>6</th> <th>7</th> <th>8</th>
                                <th>9</th>
                            </tr>
                        </thead>
                        <tbody class="endH">
                            <tr v-for="(item,index) in list" :key="index">
                                <td>{{item.issue}}</td>
                                <td v-for="(jtem,jndex) in item.weihaorows" :key="jtem">
                                    <b v-if="currentVal(jtem)" :class="`yszt${quling(item.value_num.slice(-1)[0])}`">{{jtem}}</b>
                                    <span v-else>{{ jtem }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="custom class" v-if="currentIndex==2">
                    <table class="table1">
                        <thead>
                            <tr>
                                <th style="width: 26%;">期号</th> <th>0</th> <th>1</th> <th>2</th> <th>3</th> <th>4</th>
                            </tr>
                        </thead>
                        <tbody class="headH">
                            <tr v-for="(item,index) in list" :key="index">
                                <td>{{item.issue}}</td>
                                <td v-for="(jtem,jndex) in item.touhaorows" :key="jtem">
                                    <b v-if="currentVal(jtem)" :class="`yszt${quling(item.value_num[0])}`">{{jtem}}</b>
                                    <span v-else>{{ jtem }}</span>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div class="custom class" v-if="currentIndex==3">
                    <table class="table1">
                        <thead>
                            <tr>
                                <th style="width: 26%;">期号</th> <th>红波</th> <th>蓝波</th> <th>绿波</th>
                            </tr>
                        </thead>
                        <tbody class="boSeH">
                    <tr v-for="(item,index) in list" :key="index">
                        <td>{{item.issue}}</td>
                        <td v-for="(jtem,jndex) in item.colorrows" :key="jtem">
                            <b v-if="currentVal(jtem)" :class="`yszt${quling(jtem)}`">{{jtem}}</b>
                            <span v-else>{{ jtem }}</span>
                        </td>

                    </tr>
                </tbody>
            </table>
                </div>
                <div class="custom class" v-if="currentIndex==4">
                    <table class="table1">
                        <thead>
                            <tr>
                                <th style="width: 26%;">期号</th> <th>金</th> <th>木</th> <th>水</th> <th>火</th> <th>土</th>
                            </tr>
                        </thead>
                        <tbody class="wxH">
                            <tr v-for="(item,index) in list" :key="index">
                    <td>{{item.issue}}</td>
                    <td v-for="(jtem,jndex) in item.wuxingrows" :key="jtem">
                            <b v-if="currentVal(jtem)" :class="`yszt${quling(jtem)}`">{{jtem}}</b>
                            <span v-else>{{ jtem }}</span>
                        </td>
                </tr></tbody></table></div>
                <div class="custom class" v-if="currentIndex==5">
                    <table class="table1">
                        <thead>
                            <tr>
                                <th style="width: 15%;">期号</th> <th>鼠</th> <th>牛</th> <th>虎</th> <th>兔</th> <th>龙</th> <th>蛇</th> <th>马</th> <th>羊</th> <th>猴</th> <th>鸡</th> <th>狗</th> <th>猪</th>
                            </tr>
                        </thead>
                        <tbody class="shengH">
                            <tr v-for="(item,index) in list" :key="index">
                    <td>{{item.issue}}</td>
                    <td v-for="(jtem,jndex) in item.shengxiaorows" :key="jtem">
                            <b v-if="currentVal(jtem)" :class="`yszt${quling(jtem)}`">{{jtem}}</b>
                            <span v-else>{{ jtem }}</span>
                        </td>

                </tr></tbody></table></div></div>
                <div class="moreMore" @click="more"><a href="javascript:;" class="weui-btn weui-btn_plain-default">更多</a></div></div>
</div>
</template>
<script>
import {getXglhc} from '../utils/api'
export default {
    name:'sit-type',
    data(){
        return {
            nav:['开奖','尾号','头号','波色','五行','生肖'],
            currentIndex:0,
            nowArrHome:1,
            isShow:true,
            color:{
                '蓝':'blue',
                '红':'red',
                '绿':'green'
            },
            colorIndex:{
                '红':0,
                '蓝':1,
                '绿':2
            },
            wuxingIndex:{
                '金':0,
                '木':1,
                '水':2,
                '火':3,
                '土':4
            },
            shengxiaoIndex:{
                '鼠':0,
                '牛':1,
                '虎':2,
                '兔':3,
                '龙':4,
                '蛇':5,
                '马':6,
                '羊':7,
                '猴':8,
                '鸡':9,
                '狗':10,
                '猪':11
            },
            info:null,
            page:1,
            row:1,
            list:[]
        }
    },
    methods:{
        currentVal(val){
            return (typeof val) === 'string'
        },
        showCai(){
            this.isShow=true
            this.page = 1
            this.row = 1
            this.list = []
            if(this.isShow) this.getList()
        },
        quling(val){
            return val.replace(/\b(0+)/gi,"")
        },
        more(){
            this.page++
            this.row = 5
            this.getList()
        },
        formatDate(dateString) {
            // 创建一个 Date 对象
            const date = new Date(dateString);

            // 获取年、月、日、星期
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1，并补零
            const day = String(date.getDate()).padStart(2, '0'); // 补零
            const weekDays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            const weekDay = weekDays[date.getDay()];

            // 格式化输出
            return `${year}年${month}月${day}日${weekDay}`;
        } ,
        async getList(){
            let fenRes = await getXglhc({page:this.page - 1 > 0 ? this.page - 1 : this.page,row:this.row})
            console.log(fenRes,'6666666666666666666666666666666')
            const list1  = [...this.list,...fenRes]
            list1.forEach((item,index)=>{
                item.value_num = (typeof item.value_num)=='string'?item.value_num.split(","):item.value_num
                item.value_color = (typeof item.value_color)=='string'?item.value_color.split(","):item.value_color
                item.shengxiao = (typeof item.shengxiao)=='string'?item.shengxiao.split(","):item.shengxiao
                item.wuxing =  (typeof item.wuxing)=='string'?item.wuxing.split(","):item.wuxing
                item.weihaorows = []
                item.touhaorows = []
                item.colorrows = []
                item.wuxingrows=[]
                item.shengxiaorows=[]
                let currentweival =  parseInt((item.value_num.slice(-1)[0]).charAt((item.value_num.slice(-1)[0]).length-1));
                let currenttouval = parseInt((item.value_num[0]).charAt(0));
                let colorVal = this.colorIndex[(item.value_color.slice(-1)[0])]
                let wuxingVal = this.wuxingIndex[(item.wuxing.slice(-1)[0])]
                let shengxiaoVal = this.shengxiaoIndex[(item.shengxiao.slice(-1)[0])]
                console.log(colorVal,'000000000000000')
                for(let i=0;i<10;i++){
                    if(currentweival===i) item.weihaorows.push(item.value_num.slice(-1)[0])
                    else{

                        if(list1[index-1]){
                            let ci =list1[index-1].weihaorows
                            let type = (typeof (ci[i]))
                                if(type==='string'){
                                    item.weihaorows.push(1)
                                }else{
                                    const add = ci[i]+1
                                    item.weihaorows.push(add)
                                }


                        }else{
                            item.weihaorows.push(1)
                        }
                    }
                }

                for(let i=0;i<5;i++){
                    if(currenttouval===i) item.touhaorows.push(item.value_num[0])
                    else{

                        if(list1[index-1]){
                            let ci =list1[index-1].touhaorows
                            let type = (typeof (ci[i]))
                                if(type==='string'){
                                    item.touhaorows.push(1)
                                }else{
                                    const add = ci[i]+1
                                    item.touhaorows.push(add)
                                }


                        }else{
                            item.touhaorows.push(1)
                        }
                    }
                }

                for(let i=0;i<3;i++){
                    if(colorVal===i) item.colorrows.push(item.value_num[item.value_num.length-1])
                    else{

                        if(list1[index-1]){
                            let ci =list1[index-1].colorrows
                            let type = (typeof (ci[i]))
                                if(type==='string'){
                                    item.colorrows.push(1)
                                }else{
                                    const add = ci[i]+1
                                    item.colorrows.push(add)
                                }


                        }else{
                            item.colorrows.push(1)
                        }
                    }
                }

                for(let i=0;i<5;i++){
                    if(wuxingVal===i) item.wuxingrows.push(item.value_num[item.value_num.length-1])
                    else{

                        if(list1[index-1]){
                            let ci =list1[index-1].wuxingrows
                            let type = (typeof (ci[i]))
                                if(type==='string'){
                                    item.wuxingrows.push(1)
                                }else{
                                    const add = ci[i]+1
                                    item.wuxingrows.push(add)
                                }


                        }else{
                            item.wuxingrows.push(1)
                        }
                    }
                }

                for(let i=0;i<12;i++){
                    if(shengxiaoVal===i) item.shengxiaorows.push(item.value_num[item.value_num.length-1])
                    else{

                        if(list1[index-1]){
                            let ci =list1[index-1].shengxiaorows
                            let type = (typeof (ci[i]))
                                if(type==='string'){
                                    item.shengxiaorows.push(1)
                                }else{
                                    const add = ci[i]+1
                                    item.shengxiaorows.push(add)
                                }


                        }else{
                            item.shengxiaorows.push(1)
                        }
                    }
                }
            })
            this.list=list1
        },
        getInfo(){
            getXglhc().then(res=>{
                console.log(res,'=======')
                const data = res[0]
                data.value_num = data.value_num.split(",")
                data.value_color = data.value_color.split(",")
                data.shengxiao = data.shengxiao.split(",")
                data.wuxing = data.wuxing.split(",")
                this.info=data
            })
            this.showCai()
        }
    },
    created(){
        // this.getInfo()
    }
}
</script>
<style lang="less">
.container{
    // .lot-type{
    //     border-left: 1px solid #dedede;
    //     border-top: 1px solid #dedede;
    //     margin: .5rem .5rem 0;
    //     height: 38px;

    // }
    // .lot-types{
    //     display: -webkit-box;
    //     display: -ms-flexbox;
    //     display: flex;
    //     -webkit-box-pack: center;
    //     -ms-flex-pack: center;
    //     justify-content: center;
    //     -webkit-box-align: center;
    //     -ms-flex-align: center;
    //     align-items: center;
    //     -ms-flex-wrap: wrap;
    //     flex-wrap: wrap;
    //     a{
    //         width: 100%;
    //         display: block;
    //         height: 38px;
    //         text-align: center;
    //         line-height: 38px;
    //         border-right: 1px solid #dedede;
    //         border-bottom: 1px solid #dedede;
    //         font-size: 14px;
    //         font-weight: 700;
    //         color: #333;
    //         text-decoration: none;
    //         padding-left: 0;
    //         padding-right: 0;
    //         background: #fff;
    //     }
    // }
}
</style>
