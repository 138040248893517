<style>
.blinking-text {
  font-size: 24px;
  font-weight: bold;
  color: red;
  animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.scratch-wrapper {
  width: 100%; /* 容器宽度，父组件决定 */
  max-width: 400px;
  margin: 0 auto;
  aspect-ratio: 2 / 1; /* 确保固定宽高比 */
  border: 1px solid #ddd;
  position: relative;
}

.footer {
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  color: blue;
  text-align: center;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

</style>
<template>
  <div>
    <div class="lot-type lot-types lot-typess">
      <div class="div" :style="`width:${index>=0?'50%':'33%'}; padding: 0px; margin: 0px;`" @click="changeNav(index)" v-for="(item,index) in nav" :key="index">
        <a data-type="1" :class="{active:index===currentIndex}">{{item}}</a>
      </div>
    </div>

    <template v-for="(item,index) in data" :key="index">
      <div id="ads_txt" v-if="item.type=='text'">
        <div style="margin: 0px 0.5rem;">
          <a :href="item.ad_link" target="_blank">{{item.ad_content}}</a>
        </div>
      </div>

      <div id="ads_3" v-if="item.type=='image'"><a :href="item.ad_link" target="_blank"><img :src="item.ad_image"></a></div>
    </template>


    <div class="blinking-text" style="text-align:center;">
      全网最快开奖：<a href="https://www.pc28xx.top">pc28xx.top</a>
    </div>
    <div class="lot-m" style="margin: 0.5rem;" v-if="info"><div class="lot-latest">
      <div style="display: inline-block;">
        <div class="t-logo"><!---->
          <img :src="logo[currentIndex]"> <!----> <!----> <!----> <!----> <!----> <!---->
        </div>

        <div class="lot-section">
          <p>最新：<i>{{ninfo?ninfo.expect:info.expect}}</i> 期</p>
          <div class="lot-section-sel pull-left" style="margin-top: -5px;">
            <div class="btn-group" style="display: inline-block; vertical-align: middle;">
              <button type="button" class="btn btn-default btn-sm border-radius0" @click="preIssue">
                <i class="fa fa-chevron-left"></i>
              </button>
              <div class="btn-group" style="display: inline-block; vertical-align: middle; float: left;">
                <button class="btn btn-default btn-sm dropdown-toggle border-radius0">
                  {{ninfo?ninfo.expect:info.expect}}<span class="caret h-caret"></span>
                </button>
                <ul class="dropdown-menu dropdown-select" style="overflow-y: auto; display: none;">
                  <li class="active">
                    <a href="javascript:;">
                      <input name="d-s-r" type="radio"> 3194072
                    </a>
                  </li>
                  <li class="">
                    <a href="javascript:;">
                      <input name="d-s-r" type="radio"> 3194071
                    </a>
                  </li>
                  <li class="">
                    <a href="javascript:;">
                      <input name="d-s-r" type="radio"> 3194070</a>
                  </li>
                  <li class="">
                    <a href="javascript:;">
                      <input name="d-s-r" type="radio"> 3194069
                    </a>
                  </li>
                  <li class="">
                    <a href="javascript:;">
                      <input name="d-s-r" type="radio"> 3194068
                    </a>
                  </li>
                  <li class="">
                    <a href="javascript:;">
                      <input name="d-s-r" type="radio"> 3194067
                    </a>
                  </li>
                  <li class="">
                    <a href="javascript:;">
                      <input name="d-s-r" type="radio"> 3194066
                    </a>
                  </li>
                  <li class="">
                    <a href="javascript:;">
                      <input name="d-s-r" type="radio"> 3194065
                    </a>
                  </li>
                  <li class="">
                    <a href="javascript:;">
                      <input name="d-s-r" type="radio"> 3194064
                    </a>
                  </li>
                  <li class="">
                    <a href="
                                            javascript:;"><input name="d-s-r" type="radio"> 3194063</a>
                  </li>
                  <li class="">
                    <a href="javascript:;"><input name="d-s-r" type="radio"> 3194062</a>
                  </li>
                  <li class="">
                    <a href="javascript:;"><input name="d-s-r" type="radio"> 3194061</a>
                  </li>
                  <li class="">
                    <a href="javascript:;"><input name="d-s-r" type="radio"> 3194060</a>
                  </li>
                  <li class="">
                    <a href="javascript:;"><input name="d-s-r" type="radio"> 3194059</a>
                  </li>
                  <li class="">
                    <a href="javascript:;"><input name="d-s-r" type="radio"> 3194058</a>
                  </li>
                  <li class="">
                    <a href="javascript:;"><input name="d-s-r" type="radio"> 3194057</a>
                  </li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194056</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194055</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194054</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194053</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194052</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194051</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194050</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194049</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194048</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194047</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194046</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194045</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194044</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194043</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194042</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194041</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194040</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194039</a></li>
                  <li class=""><a href="javascript:;"><input name="d-s-r" type="radio"> 3194038</a></li>
                </ul>
              </div>
              <button type="button" class="btn btn-default btn-sm border-radius0" @click="addIssue"><i class="fa fa-chevron-right"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="open-cutdown">
        <div class="opentime">
          <span>下一期:</span><b style="display: none;">00</b>
          <span style="display: none;">小时</span>
          <b>{{m}}</b><span>分</span><b>{{s}}</b><span>秒</span>
        </div>
      </div>

      <div class="lot-result">
        <div v-if="ms>0">
          <div v-if="currentIndex == 2">
          <ScratchCard ref="scratchCard" :enableMask="enableMask" :clearThreshold="40" coverColor="silver"  hintText="轻划刮开当期开奖" textFont="18px Arial" @cleared="onCardCleared">
            <div class="lot-result">
              <div class="r1" >
                <span class="cyanBlue">{{ninfo?ninfo.value_num[0]:info.value_num[0]}}</span>
                <label>+</label>
                <span class="cyanBlue">{{ninfo?ninfo.value_num[1]:info.value_num[1]}}</span>
                <label>+</label>
                <span class="cyanBlue">{{ninfo?ninfo.value_num[2]:info.value_num[2]}}</span>
                <label>=</label>
                <span class="wd_blue">{{ninfo?ninfo.value_sum:info.value_sum }}</span>
              </div>
              <div class="r2" >
                <span :class="`${(ninfo?ninfo.value_sum_dx:info.value_sum_dx)=='大'?'red':'blue'}-info`">{{ninfo?ninfo.value_sum_dx:info.value_sum_dx}}</span>
                <span :class="`${(ninfo?ninfo.value_sum_ds:info.value_sum_ds)=='双'?'red':'blue'}-info`">{{ninfo?ninfo.value_sum_ds:info.value_sum_ds}}</span>
              </div>
            </div>
          </ScratchCard>
          </div>
          <div  v-else>
            <div class="lot-result">
              <div class="r1" >
                <span class="cyanBlue">{{ninfo?ninfo.value_num[0]:info.value_num[0]}}</span>
                <label>+</label>
                <span class="cyanBlue">{{ninfo?ninfo.value_num[1]:info.value_num[1]}}</span>
                <label>+</label>
                <span class="cyanBlue">{{ninfo?ninfo.value_num[2]:info.value_num[2]}}</span>
                <label>=</label>
                <span class="wd_blue">{{ninfo?ninfo.value_sum:info.value_sum }}</span>
              </div>
              <div class="r2" >
                <span :class="`${(ninfo?ninfo.value_sum_dx:info.value_sum_dx)=='大'?'red':'blue'}-info`">{{ninfo?ninfo.value_sum_dx:info.value_sum_dx}}</span>
                <span :class="`${(ninfo?ninfo.value_sum_ds:info.value_sum_ds)=='双'?'red':'blue'}-info`">{{ninfo?ninfo.value_sum_ds:info.value_sum_ds}}</span>
              </div>
            </div>
          </div>
        </div>
        <span class="r3" style="" v-if="ms<=0&&num60<360">等待结果 <img src="../assets/icon-status-status-dots-anim-16.gif"></span>
        <span class="r3" style="" v-if="ms<=0&&num60>=360">官方暂停开奖！！！</span>
      </div>
    </div>


    <!-- <div id="ads_2"><a href="/url/zs_txt.php" target="_blank">
        <img src="../assets/zs960-m.gif"></a>
        <a href="https://ddyxc666.com/#/login?i=115665
        " target="_blank"><img src="../assets/dd28.gif"></a>
    </div> -->

    <!-- <div class="lot-for-header" style="margin: 1rem 0.5rem 0px;" @click="yuce=!yuce"><a><i class="fa fa-arrow-up"></i> 预测</a></div> -->

    <div id="ads_2">
      <template v-for="(item,index) in bottom" :key="index">
        <a :href="item.ad_link" target="_blank" v-if="item.type=='image'" :alt="item.ad_title">
          <img :src="item.ad_image">
        </a>

        <a :href="item.ad_link" target="_blank" v-if="item.type=='text'" :alt="item.ad_title">
          {{ item.ad_content }}
        </a>
      </template>

      <!-- <a href="https://ddyxc666.com/#/login?i=115665
          " target="_blank"><img src="../assets/djw960x120-2.gif?v1">
      </a>
      <a href="/url/txt_tm.php" target="_blank"><img src="../assets/tmgj.gif?v1"></a> -->
    </div>
    <div v-if="yuce">
      <div class="panel calc-panel" style="">
        <table class="table table-striped b-light">
          <thead>
          <tr>
            <th>期号</th> <th>时间</th> <th>预测</th> <th>开奖</th> <th>结果</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>3194033</td> <td></td>
            <td><i class="red-text">单</i>+<i class="red-text">小</i></td>
            <td class="red-text">---</td> <td><i class="fa blue-text">---</i> <!----></td>
          </tr>
          <tr v-for="(item,index) in yuceArr" :key="index">
            <td>{{ item.qishu }}</td>
            <td>{{item.time}}</td>
            <td><i class="red-text">{{item.shuang}}</i>+<i class="red-text">{{item.da}}</i></td>
            <td class="red-text">{{item.num}}</td>
            <td><!----> <i class="fa fa-check"></i></td>
          </tr>
          </tbody>
        </table>
        <div class="moreMore" @click="moreTap1"><a href="javascript:;" class="weui-btn weui-btn_plain-default">更多</a></div></div>
    </div>


    <div class="panel" style="margin: 0.5rem;" v-if="currentIndex<=2">
      <div style="padding: 0.8rem; background: rgb(245, 245, 245); border-bottom: none;">
        <div class="btn-group" style="display: flex;">
                    <span class="arrow bottom " :class="isRes?'h-ab-trend':'h-ab-result'">

                    </span>
          <a :class="isRes?'btn-twitter':'btn-facebook'" @click="isRes=false"><i class="fa fa-key"></i>结果</a>
          <a :class="!isRes?'btn-twitter':'btn-facebook'" @click="isRes=true"><i class="fa fa-bar-chart-o"></i>走势图</a>
          <div style="clear: both;"></div>
        </div>
      </div>
      <div style="" v-if="!isRes">
        <table class="table table-striped b-light table-tt1">
          <thead><tr><th>期号</th> <th>时间</th> <th>号码</th></tr></thead>
          <tbody>
          <tr v-for="(item,index) in list" :key="index">
            <td>{{item.expect}}</td>
            <td>{{item.opentime_text}}</td>
            <td>{{item.value_num.replace(/,/g,'+')}}={{ item.value_sum }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div  v-else>
        <table class="table table-striped tbl-trend b-light">
          <thead>
          <tr>
            <th>期号</th> <th>值</th> <th>大</th> <th>小</th> <th>单</th> <th>双</th> <th>大单</th> <th>大双</th> <th>小单</th> <th>小双</th>
          </tr>
          </thead>
          <tbody>
          <tr style="text-align: center;">
            <td>间隔</td> <td></td>
            <td class="font-bold">{{countNum('大','value_sum_dx')}}</td>
            <td class="font-bold">{{countNum('小','value_sum_dx')}}</td>
            <td class="font-bold">{{countNum('单','value_sum_ds')}}</td>
            <td class="font-bold">{{countNum('双','value_sum_ds')}}</td>
            <td class="font-bold">{{countNum('大单','value_sum_dxds')}}</td>
            <td class="font-bold">{{countNum('大双','value_sum_dxds')}}</td>
            <td class="font-bold">{{countNum('小单','value_sum_dxds')}}</td>
            <td class="font-bold">{{countNum('小双','value_sum_dxds')}}</td>
          </tr>
          <tr v-for="(item,index) in list" :key="index">
            <td>{{item.expect}}</td>
            <td class="font-bold">{{item.value_sum}}</td>
            <td><span class="badge red-info" v-if="item.value_sum_dx=='大'">大</span></td>
            <td><span class="badge blue-info" v-if="item.value_sum_dx=='小'">小</span></td>
            <td><span class="badge blue-info" v-if="item.value_sum_ds=='单'">单</span></td>
            <td><span class="badge red-info" v-if="item.value_sum_ds=='双'">双</span></td>
            <td><span class="badge blue-info" v-if="item.value_sum_dxds=='大单'">大单</span></td>
            <td><span class="badge red-info" v-if="item.value_sum_dxds=='大双'">大双</span></td>
            <td><span class="badge blue-info" v-if="item.value_sum_dxds=='小单'">小单</span></td>
            <td><span class="badge red-info" v-if="item.value_sum_dxds=='小双'">小双</span></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-else  class="panel">
      <div class="feiting-bar" style="">
        <div class="btn-group">
          <a data-type="0"
             v-for="(item,index) in groupArr" :key="index"
             @click="groupIndex=index"
             :class="groupIndex==index?'feiting-bar-item-hover':'feiting-bar-item-n'">{{item}}</a>
          <div style="clear: both;"></div>

        </div></div>
      <div style="width: 100%; border: 1px solid rgb(220, 220, 220); overflow: hidden;">
        <table class="table table-striped b-light table-tt1" style="border: none;">
          <thead class="feiting-table-head">
          <tr>
            <th>期数</th> <th><span>冠</span><span>亚</span><span>三</span><span>四</span><span>五</span><span>六</span><span>七</span><span>八</span><span>九</span><span>十</span></th> <th>时间</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>028期</td> <td><p class="table-num"><span data-val="6" class="table-num-6"></span> <span data-val="5" class="table-num-5"></span> <span data-val="7" class="table-num-7"></span> <span data-val="2" class="table-num-2"></span> <span data-val="4" class="table-num-4"></span> <span data-val="1" class="table-num-1"></span> <span data-val="3" class="table-num-3"></span> <span data-val="10" class="table-num-10"></span> <span data-val="9" class="table-num-9"></span> <span data-val="8" class="table-num-8"></span></p> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!---->
          </td>
            <td>15:24</td>
          </tr>
          <tr><td>027期</td> <td><p class="table-num"><span data-val="4" class="table-num-4"></span> <span data-val="6" class="table-num-6"></span> <span data-val="3" class="table-num-3"></span> <span data-val="9" class="table-num-9"></span> <span data-val="8" class="table-num-8"></span> <span data-val="2" class="table-num-2"></span> <span data-val="1" class="table-num-1"></span> <span data-val="5" class="table-num-5"></span> <span data-val="10" class="table-num-10"></span> <span data-val="7" class="table-num-7"></span></p> <!----> <!----> <!----> <!----> <!----> <!----> <!----> <!----></td> <td>15:19</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="moreMore" @click="moreTap"><a href="javascript:;" class="weui-btn weui-btn_plain-default">更多</a></div>



    <div id="bottombox">
      <div id="leftbox" class="leftbox" v-if="left.ad_link">
        <div id="leftbar" class="leftbar">
          <a :href="left.ad_link" target="_blank"><img :src="left.ad_image" width="60px" height="60"></a>
        </div>
      </div>
      <div id="rightbox" class="rightbox" v-if="right.ad_link">
        <div id="rightbar" class="rightbar">
          <a  :href="right.ad_link"  target="_blank"><img  :src="right.ad_image"  width="60px" height="60"></a>
        </div>
      </div>
    </div>


  </div>
  <div class="footer">
    意见反馈：16156864@qq.com
  </div>
</template>


<script>
import {getJnd28,getJs28} from '../utils/api';
import ScratchCard from "../components/ScratchCard";
export default {
  name:'sit-content',
  components: {
    ScratchCard,
  },
  props:{
    data:{
      type:Array,
      default(){
        return []
      },
    },
    bottom:{
      type:Array,
      default(){
        return []
      },
    },
    left:{
      type:Object,
      default(){
        return {}
      },
    },
    right:{
      type:Object,
      default(){
        return {}
      },
    }

  },
  data(){
    return{
      nav:['急速28','加拿大28','刮刮乐pc28'
        // '幸运飞艇(休市)','澳洲幸运10','澳洲幸运5'
      ],
      logo:[
        require('../assets/bjkl8_logo.png'),
        require('../assets/canada_logo.png'),
        require('../assets/feiting_logo.png'),
        require('../assets/azluck10_logo.png'),
        require('../assets/azluck5_logo.png')
      ],
      groupArr:['号码','大小','单双','对子','冠亚龙虎','综合'],
      groupIndex:0,
      currentIndex:1,
      enableMask: true,
      yuce:false,
      nQi:200000,
      ms:60,
      isRes:false,
      info:null,
      ninfo:null,
      page:1,
      m:'00',
      s:'00',
      num60:0,
      res1:[
        {num:'00000001', time:'09-28 16:29:30',result:'3+1+7=11'},
        {num:'00000001', time:'09-28 16:29:30',result:'3+1+7=11'},
        {num:'00000001', time:'09-28 16:29:30',result:'3+1+7=11'},
        {num:'00000001', time:'09-28 16:29:30',result:'3+1+7=11'},
        {num:'00000001', time:'09-28 16:29:30',result:'3+1+7=11'},
        {num:'00000001', time:'09-28 16:29:30',result:'3+1+7=11'}
      ],
      res2:[
        {num:'00000001',num1:'11',num2:'单',num3:'小',num4:'单',num5:'小',num6:'单',num7:'小',num8:'单',num9:'单'},
        {num:'00000001',num1:'11',num2:'单',num3:'小',num4:'单',num5:'小',num6:'单',num7:'小',num8:'单',num9:'单'},
        {num:'00000001',num1:'11',num2:'单',num3:'小',num4:'单',num5:'小',num6:'单',num7:'小',num8:'单',num9:'单'},
        {num:'00000001',num1:'11',num2:'单',num3:'小',num4:'单',num5:'小',num6:'单',num7:'小',num8:'单',num9:'单'},
        {num:'00000001',num1:'11',num2:'单',num3:'小',num4:'单',num5:'小',num6:'单',num7:'小',num8:'单',num9:'单'}
      ],
      yuceArr:[
        {qishu:'002022',time:'24-09 10:23:20',shuang:'双',da:'大',num:'22'},
        {qishu:'002022',time:'24-09 10:23:20',shuang:'双',da:'大',num:'22'},
        {qishu:'002022',time:'24-09 10:23:20',shuang:'双',da:'大',num:'22'},
        {qishu:'002022',time:'24-09 10:23:20',shuang:'双',da:'大',num:'22'},
        {qishu:'002022',time:'24-09 10:23:20',shuang:'双',da:'大',num:'22'},
        {qishu:'002022',time:'24-09 10:23:20',shuang:'双',da:'大',num:'22'}
      ],
      list:[]
    }
  },
  methods:{
    onCardCleared() {
      console.log("恭喜您刮开了涂层！");
    },
    changeNav(index){
      this.currentIndex=index
      if(this.currentIndex == 1) {
        this.enableMask = false;
      } else if(this.currentIndex == 2) {
        this.enableMask = true;
      } else {
        this.enableMask = false;
      }
      this.getInfo()
    },
    async preIssue(){
      let pre = parseInt(this.ninfo?this.ninfo.expect:this.info.expect) - 1
      let res  = null
      if(this.currentIndex==0){
        res = await getJs28({expect:pre})
      }else{
        res = await getJnd28({expect:pre})
      }

      this.ninfo = res
      this.ninfo.value_num = this.ninfo.value_num.split(',')

    },

    async addIssue(){
      let pre = parseInt(this.ninfo?this.ninfo.expect:this.info.expect) + 1
      let res  = null
      if(this.currentIndex==0){
        res = await getJs28({expect:pre})
      }else{
        res = await getJnd28({expect:pre})
      }
      if(res) {
        this.ninfo = res
        this.ninfo.value_num = this.ninfo.value_num.split(',')
      }

    },
    countNum(val,key){
      let num = 0
      for(let i=0;i<this.list.length;i++){
        if(this.list[i][key]==val) break
        else num = i+1
      }
      return num
    },
    async getInfo(){
      let res  = null
      if(this.currentIndex==0){
        res = await getJs28()
      }else{
        res = await getJnd28()
      }

      this.info = res[0]
      // this.info.drawTime
      // this.info.opentime
      this.info.value_num = this.info.value_num.split(',')
      console.log(res,'-==================')
      let n = +new Date()
      console.log(n,'=00000')
      //    console.log(parseInt(this.info.drawTime)-parseInt(n/1000),'77777777777777')
      let num = parseInt(this.info.drawTime||0)-parseInt(n/1000)
      this.ms = num>0?num:0
      this.setInt&&clearTimeout(this.setInt)
      this.setInt1&&clearInterval(this.setInt1)
      this.cuntime()
      this.getList(1)
    },
    async getNew(){
      let res  = null
      if(this.currentIndex==0){
        res = await getJs28()
      }else{
        res = await getJnd28()
      }
      this.info = res[0]
      // this.info.drawTime
      // this.info.opentime
      const value_num = this.info.value_num
      this.info.value_num = this.info.value_num.split(',')
      // console.log(res,'-==================')
      let n = +new Date()
      // console.log(n,'=00000')
      //    console.log(parseInt(this.info.drawTime)-parseInt(n/1000),'77777777777777')
      let num = parseInt(this.info.drawTime||0)-parseInt(n/1000)
      this.ms = num>0?num:0
      // console.log(this.ms,'...............')
      if(this.ms===0) this.num60 +=1
      else this.num60 = 0
      this.setInt&&clearTimeout(this.setInt)
      this.setInt1&&clearInterval(this.setInt1)
      this.cuntime()
      if(this.list.length>0){
        if(this.info.expect!=this.list[0].expect){
          this.list.unshift({...this.info,value_num:value_num})
        }
      }


    },
    async getList(val){

      let res  = null
      if(this.currentIndex==0){
        res = await getJs28({page:val?val:this.page,row:val===1?15:5})
      }else{
        res = await getJnd28({page:val?val:this.page,row:val===1?15:5})
      }
      this.list=val?[...res]:[...this.list,...res]
    },
    moreTap1(){
      let item ={qishu:'002022',time:'24-09 10:23:20',shuang:'双',da:'大',num:'22'}
      const arr = []
      for(let i =0;i<10;i++){
        arr.push(item)
      }
      this.yuceArr = [...this.yuceArr,...arr]
    },
    moreTap(){
      this.page++
      this.getList()
    },
    cuntime(){
      if(this.ms<=0) {
        this.setInt1&&clearInterval(this.setInt1)
        this.setInt1 = setInterval(()=>{
          console.log('进来')
          this.getNew()
        },1*1*1000)
        this.ms = 0
        let m = 0
        this.m = '00'
        // let s = this.ms - this.m*60
        this.s = `00`
        return
      }else{
        this.setInt&&clearInterval(this.setInt)
        this.ms--
        let m = Math.floor(this.ms/60)
        this.m = m>9?m :`0${m}`
        let s = this.ms - this.m*60
        this.s = s>9?s:`0${s}`
        this.setInt = setTimeout(()=>{
          this.cuntime()
        },1000)
      }


    }
  },
  created(){


  }
}
</script>
